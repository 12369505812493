<template>
    <website-page centered>
        <b-img :src="require('../assets/icons/logo.png')"/>
        <h1 class="mt-3" v-tr>Error|Erreur</h1>
        <p v-tr>This page doesn't exist|Cette page n'existe pas</p>
        <b-btn class="mt-3" variant="white" to="/" v-tr>Return to Home Page|Retourner à l'accueil</b-btn>
    </website-page>
</template>

<script>
export default {
    name: `default-error-page`
}
</script>

<style lang="scss" scoped>
</style>
